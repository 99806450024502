.dark {
    background-color: #333;
    color: #fff;
  }
  .light {
    background-color: #fff;
    color: #333;
  }


  .app-container  {
    background-color: #fff;
    color: #333;
  }
  .dark-mode .app-container  {
    background-color: #333;
    color: #fff;
  } 
  .shadow-sm {
    background-color: #fff;
    color: #333;
  }
  .dark-mode  .shadow-sm  {
    background-color: #333;
    color: #fff;
    /* height: 6vh; */
  }
.text-h5 {
  font-size: 1.25rem;
}